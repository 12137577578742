import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  Paper,
  Popover,
  Text,
  Tooltip,
} from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconAlertCircle, IconCopy, IconPhoto, IconPlus } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { Form, IFormRef } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { usePermission } from "../permission";
import { BrandForm } from "../reference/brand-form";
import { CategoryForm } from "../reference/category-form";
import { DistributorForm } from "../reference/distributor-form";
import { ItemTypeForm } from "../reference/item-type-form";
import { ManufacturerForm } from "../reference/manufacturer-form";
import { SubCategoryForm } from "../reference/sub-category-form";
import { SupplierForm } from "../reference/supplier-form";
import { TagForm } from "../reference/tag-form";
import { IFile, MultiImageUpload } from "../upload/multi-image-upload";

const schema = yup.object({
  skuCode: yup.string().required("Заавал бөглөнө!").nullable(),
  barCode: yup.string().required("Заавал бөглөнө!").nullable(),
  erpCode: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameMon: yup.string().required("Заавал бөглөнө!").nullable(),
  nameEng: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameBill: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameWeb: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameApp: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  brandId: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  supplierId: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  manufacturerId: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  importerCountry: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  distributorId: yup.string().max(254, "Хэт урт байна!").required("Заавал бөглөнө!").nullable().optional(),
  itemTypeId: yup.string().required("Заавал бөглөнө!").nullable(),
  classificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  subClassificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  categoryId: yup.string().required("Заавал бөглөнө!").nullable(),
  subCategoryId: yup.string().required("Заавал бөглөнө!").nullable(),
  tagId: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  originCountry: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable().optional(),
  coverImages: yup.array().min(1, "Заавал бөглөнө үү!").required("Заавал бөглөнө!").nullable(),
  detailImages: yup.array().min(1, "Заавал бөглөнө үү!").required("Заавал бөглөнө!").nullable(),
});

export type IFormDataGood = {
  skuCode: string | undefined;
  barCode: string | undefined;
  erpCode: string | undefined;
  nameMon: string | undefined;
  nameEng: string | undefined;
  nameBill: string | undefined;
  nameWeb: string | undefined;
  nameApp: string | undefined;
  brandId: string | undefined;
  supplierId: string | undefined;
  manufacturerId: string | undefined;
  originCountry: string | undefined;
  importerCountry: string | undefined;
  distributorId: string | undefined;
  itemTypeId: string | undefined;
  classificationId: string | undefined;
  subClassificationId: string | undefined;
  categoryId: string | undefined;
  subCategoryId: string | undefined;
  tagId: string | undefined;
  description: string | undefined;
  coverImages: IFile[] | string[] | any[];
  detailImages: IFile[] | string[] | any[];
  itemStatus?: string | undefined;
};

type MainFormProps = {
  editable: any[];
  onSubmit: (values: IFormDataGood, setLoading: React.Dispatch<React.SetStateAction<boolean>>, isNext: boolean) => void;
};

export function MainForm({ onSubmit: _onSubmit, editable }: MainFormProps) {
  const ERP_GDS = usePermission("ERP_GDS");
  const { classes, theme } = useStyle();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const refFrom: React.Ref<IFormRef<any>> = useRef(null);

  const [action, setAction] = React.useState<string[]>([]);
  const { brands, manufacturers, countries, distributors, itemTypes, categories, tags, itemStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const [data] = React.useState<IFormDataGood>({
    skuCode: undefined,
    barCode: undefined,
    erpCode: undefined,
    nameMon: undefined,
    nameEng: undefined,
    nameBill: undefined,
    nameWeb: undefined,
    nameApp: undefined,
    brandId: undefined,
    supplierId: undefined,
    manufacturerId: undefined,
    originCountry: undefined,
    importerCountry: undefined,
    distributorId: undefined,
    itemTypeId: undefined,
    classificationId: undefined,
    subClassificationId: undefined,
    categoryId: undefined,
    subCategoryId: undefined,
    tagId: undefined,
    description: undefined,
    coverImages: [],
    detailImages: [],
    ...(editable && editable[0] === "update"
      ? { ...editable[1], coverImages: editable[1]?.coverImages?.map((c: any) => ({ isMain: c?.isMain || false, url: c?.url || "/" })) }
      : {}),
  });

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <Paper>
      <Form ref={refFrom} validationSchema={schema} onSubmit={(e) => ""} initialValues={data}>
        {({ values, setFieldValue, errors }) => {
          return (
            <FormLayout
              my={20}
              title="Бүртгэлийн мэдээлэл"
              subTitle="Бараа бүтээгдэхүүний код, төрөл, ангилал, зураг оруулна."
              extra={[
                <Button
                  onClick={() => {
                    navigate("/product");
                  }}
                  key={1}
                  variant="default">
                  Болих
                </Button>,
                <Button
                  hidden={!ERP_GDS.isEdit}
                  type="submit"
                  onClick={async () => {
                    let isValid = await refFrom.current?.submit();
                    if (isValid) {
                      _onSubmit(isValid, setLoading, false);
                    }
                  }}
                  variant="outline"
                  key={2}>
                  Хадгалах
                </Button>,
                <Button
                  type="submit"
                  onClick={async () => {
                    let isValid = await refFrom.current?.submit();
                    if (isValid) _onSubmit(isValid, setLoading, true);
                  }}
                  key={3}>
                  Үргэлжлүүлэх
                </Button>,
              ]}>
              <LoadingOverlay visible={loading} />

              <Divider mb="lg" />

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="dehubCode" label="DeHub код:" placeholder="DeHub код" disabled />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Бүртгэлийн статус" required>
                    <Box style={{ alignItems: "center" }}>
                      <Badge size="md" color={itemStatus.find((item: IReference) => item.code === data.itemStatus)?.color ?? "cyan"}>
                        {itemStatus.find((item: IReference) => item.code === data.itemStatus)?.name ?? "Түр төлөв"}
                      </Badge>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Бүртгэсэн ажилтан" required>
                    <Input placeholder="Систем авто" disabled />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="skuCode" label="SKU код:" placeholder="SKU код" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="barCode" label="Бар код:" placeholder="Бар код" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="erpCode" label="ERP код:" placeholder="ERP код" />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField
                    rightSection={
                      <Tooltip label="Хуулах">
                        <ActionIcon
                          variant="transparent"
                          color="primary"
                          onClick={() => {
                            setFieldValue("nameWeb", values.nameMon);
                            setFieldValue("nameApp", values.nameMon);
                            setFieldValue("nameBill", values.nameMon);
                          }}>
                          <IconCopy />
                        </ActionIcon>
                      </Tooltip>
                    }
                    name="nameMon"
                    label="Барааны нэр /Монгол хэлээр/"
                    placeholder="Барааны нэр /Монгол хэлээр/"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="nameEng" label="Барааны нэр /Англи, эсвэл латин/" placeholder="Барааны нэр /Англи, эсвэл латин/" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="nameBill" label="Падаанд харуулах нэр" placeholder="Падаанд харуулах нэр" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="nameWeb" label="Веб-д гарах нэр" placeholder="Веб-д гарах нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField name="nameApp" label="Апп-д гарах нэр" placeholder="Апп-д гарах нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}></Grid.Col>
                <Grid.Col>
                  <Text fw={600} fz={"lg"}>
                    Брэнд, нийлүүлэгч
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Брэндийн нэр">
                    <Group>
                      <SelectField
                        name="brandId"
                        placeholder="Бренд"
                        options={brands.map((b) => ({ label: b.name, value: b.id }))}
                        rightSection={
                          <ActionIcon onClick={() => setAction(["BRAND"])} variant="light" color="">
                            <IconPlus />
                          </ActionIcon>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Үйлдвэрлэгч">
                    <Group>
                      <SelectField
                        name="manufacturerId"
                        placeholder="Үйлдвэрлэгч"
                        options={manufacturers.map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ActionIcon color="" variant="light" onClick={() => setAction(["MANUFACTURER"])}>
                            <IconPlus />
                          </ActionIcon>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    searchable
                    name="originCountry"
                    label="Үйлдвэрлэгч улс"
                    placeholder="Үйлдвэрлэгч улс"
                    options={countries.map((s: any) => ({ label: s.name, value: s.code }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    searchable
                    name="importerCountry"
                    label="Импортлогч улс"
                    placeholder="Импортлогч улс"
                    options={countries.map((s: any) => ({ label: s.name, value: s.code }))}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Дистрибьютер">
                    <Group>
                      <SelectField
                        name="distributorId"
                        placeholder="Дистрибьютер"
                        options={distributors.map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ActionIcon variant="light" onClick={() => setAction(["DISTRIBUTOR"])} color="">
                            <IconPlus />
                          </ActionIcon>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col>
                  <Text fw={600} fz={"lg"}>
                    Бараа хамаарах бүлэг
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Нэр төрөл" required>
                    <Group>
                      <SelectField
                        onChange={() => {
                          setFieldValue("classificationId", undefined);
                        }}
                        name="itemTypeId"
                        placeholder="Нэр төрөл"
                        options={itemTypes.map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ActionIcon variant="light" onClick={() => setAction(["ITEM_TYPE"])} color="">
                            <IconPlus />
                          </ActionIcon>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Ангилал" required>
                    <Group>
                      <SelectField
                        name="classificationId"
                        placeholder="Ангилал"
                        disabled={!values.itemTypeId}
                        onChange={() => {
                          setFieldValue("subClassificationId", null);
                          setFieldValue("categoryId", null);
                          setFieldValue("subCategoryId", null);
                        }}
                        options={categories
                          .filter((c) => c.type === "CLASSIFICATION" && c.itemTypeId === values.itemTypeId)
                          .map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ValidateButton message={!values.itemTypeId && "Нэр төрлийг заавал сонгоно уу!"}>
                            <ActionIcon variant="light" color="" onClick={() => setAction(["CLASSIFICATION", values.itemTypeId, null, null])}>
                              <IconPlus />
                            </ActionIcon>
                          </ValidateButton>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Дэд ангилал" required>
                    <Group>
                      <SelectField
                        name="subClassificationId"
                        placeholder="Дэд ангилал"
                        onChange={() => {
                          setFieldValue("categoryId", null);
                          setFieldValue("subCategoryId", null);
                        }}
                        disabled={!values.classificationId}
                        options={categories
                          .filter((c) => c.type === "SUB_CLASSIFICATION" && c.parentId === values.classificationId)
                          .map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ValidateButton message={!values.classificationId && "Ангилал заавал сонгоно уу!"}>
                            <ActionIcon
                              variant="light"
                              color=""
                              onClick={() => setAction(["SUB_CLASSIFICATION", values.itemTypeId, values.classificationId, null])}>
                              <IconPlus />
                            </ActionIcon>
                          </ValidateButton>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Категори" required>
                    <Group>
                      <SelectField
                        name="categoryId"
                        placeholder="Категори"
                        disabled={!values.subClassificationId}
                        onChange={() => {
                          setFieldValue("subCategoryId", null);
                        }}
                        options={categories
                          .filter((c) => c.type === "CATEGORY" && c.parentId === values.subClassificationId)
                          .map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ValidateButton message={!values.subClassificationId && "Дэд ангилал заавал сонгоно уу!"}>
                            <ActionIcon variant="light" color="" onClick={() => setAction(["CATEGORY", values.itemTypeId, values.subClassificationId])}>
                              <IconPlus />
                            </ActionIcon>
                          </ValidateButton>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Дэд категори" required>
                    <Group>
                      <SelectField
                        name="subCategoryId"
                        placeholder="Дэд категори"
                        disabled={!values.categoryId}
                        options={categories
                          .filter((c) => c.type === "SUB_CATEGORY" && c.parentId === values.categoryId)
                          .map((s) => ({ label: s.name, value: s.id }))}
                        rightSection={
                          <ValidateButton message={!values.categoryId && "Категори заавал сонгоно уу!"}>
                            <ActionIcon
                              variant="light"
                              color=""
                              onClick={() =>
                                setAction([
                                  "SUB_CATEGORY",
                                  values.itemTypeId,
                                  values.categoryId,
                                  values.classificationId,
                                  values.subClassificationId,
                                  values.categoryId,
                                ])
                              }>
                              <IconPlus />
                            </ActionIcon>
                          </ValidateButton>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Таг">
                    <Group>
                      <SelectField
                        name="tagId"
                        placeholder="Таг"
                        options={tags.map((s) => ({ label: s.text, value: s.id }))}
                        rightSection={
                          <ActionIcon variant="light" color="" onClick={() => setAction(["TAG"])}>
                            <IconPlus />
                          </ActionIcon>
                        }
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col>
                  <Text fw={600} fz={"lg"}>
                    Маркетингийн мэдээлэл
                  </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="description" placeholder="Тайлбар оруулах" label="Тайлбар оруулах" max={255} />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Input.Wrapper label="Нүүрэнд харагдах зураг" required error={errors.coverImages}>
                    {(() => {
                      const image = values?.coverImages?.find((i: any) => i.isMain);
                      return image ? (
                        <Flex direction="row" gap={15}>
                          <Image src={image?.url} style={{ width: 260, height: 160 }} my={10} fit="cover" className={classes.addImage} />
                          <Image src={image?.url} style={{ width: 130, height: 160 }} my={10} fit="cover" className={classes.addImage} />
                          <Image src={image?.url} style={{ width: 60, height: 60 }} my={10} fit="cover" className={classes.addImage} />
                        </Flex>
                      ) : (
                        <Flex direction="row" gap={15}>
                          <Box w={280} h={160} className={classes.addImage} my={10}>
                            <IconPhoto size={32} color={theme.colors.gray[7]} />
                          </Box>
                          <Box w={130} h={160} className={classes.addImage} my={10}>
                            <IconPhoto size={32} color={theme.colors.gray[7]} />
                          </Box>
                          <Box w={60} h={60} className={classes.addImage} my={10}>
                            <IconPhoto size={32} color={theme.colors.gray[7]} />
                          </Box>
                        </Flex>
                      );
                    })()}
                    <MultiImageUpload
                      initialData={values.coverImages}
                      max={5}
                      onChange={(images) => {
                        setFieldValue(
                          "coverImages",
                          images.map((c: any) => ({ url: c.url, isMain: c?.selected })),
                        );
                      }}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Input.Wrapper label="Бүтээгдэхүүний дэлгэрэнгүй мэдээллийг нэмэлт зургаар" error={errors.detailImages} required>
                    {(() => {
                      return values.detailImages?.length > 0 ? (
                        <Image src={values.detailImages[0]} style={{ width: 280, height: 160 }} my={10} fit="cover" className={classes.addImage} />
                      ) : (
                        <Box w={280} h={160} className={classes.addImage} my={10}>
                          <IconPhoto size={32} color={theme.colors.gray[7]} />
                        </Box>
                      );
                    })()}
                    <MultiImageUpload
                      onChange={(images) =>
                        setFieldValue("detailImages", [
                          ...images.map((item) => {
                            return item.url;
                          }),
                        ])
                      }
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </FormLayout>
          );
        }}
      </Form>

      <Modal opened={action[0] === "BRAND"} onClose={() => setAction([])} withCloseButton={false} centered>
        <BrandForm onCancel={onCancel} action={[]} />
      </Modal>

      <Modal opened={action[0] === "SUPPLIER"} onClose={() => setAction([])} withCloseButton={false} centered>
        <SupplierForm onCancel={onCancel} />
      </Modal>

      <Modal opened={action[0] === "MANUFACTURER"} onClose={() => setAction([])} withCloseButton={false} centered>
        <ManufacturerForm title="Үйлдвэрлэгч" subTitle="Үйлдвэрлэгч нэмэх" onCancel={onCancel} action={action} />
      </Modal>

      <Modal opened={action[0] === "DISTRIBUTOR"} onClose={() => setAction([])} withCloseButton={false} centered>
        <DistributorForm title="Дистрибьютер" subTitle="Дистрибьютер нэмэх" onCancel={onCancel} action={[]} />
      </Modal>

      <Modal opened={action[0] === "ITEM_TYPE"} onClose={() => setAction([])} withCloseButton={false} centered>
        <ItemTypeForm title="Нэр төрөл" subTitle="Нэр төрөл нэмэх" onCancel={onCancel} action={[]} />
      </Modal>

      <Modal opened={action[0] === "CLASSIFICATION"} onClose={() => setAction([])} withCloseButton={false} centered>
        <CategoryForm title="Ангиалалын мэдээлэл" subTitle="Ангиалалын нэр, тайлбар оруулна." action={action} onCancel={onCancel} />
      </Modal>

      <Modal opened={action[0] === "SUB_CLASSIFICATION"} onClose={() => setAction([])} withCloseButton={false} centered>
        <CategoryForm title="Дэд Ангиллын мэдээлэл" subTitle="Дэд Ангиллын нэр, тайлбар оруулна." action={action} onCancel={onCancel} />
      </Modal>

      <Modal opened={action[0] === "CATEGORY"} onClose={() => setAction([])} withCloseButton={false} centered>
        <CategoryForm title="Категорийн мэдээлэл" subTitle="Категорийн нэр, тайлбар оруулна." action={action} onCancel={onCancel} />
      </Modal>

      <Modal opened={action[0] === "SUB_CATEGORY"} onClose={() => setAction([])} withCloseButton={false} size="90%" centered>
        <SubCategoryForm action={action} onCancel={onCancel} />
      </Modal>

      <Modal opened={action[0] === "TAG"} onClose={() => setAction([])} withCloseButton={false} centered>
        <TagForm title="Таг" subTitle="Таг нэмэх" onCancel={onCancel} action={[]} />
      </Modal>
    </Paper>
  );
}

type ValidateButtonProps = {
  children: JSX.Element;
  message: string | undefined | false;
};

const ValidateButton = ({ children, message }: ValidateButtonProps) => {
  if (!message) return children;

  return (
    <Popover width={400} position="top" withArrow shadow="sm">
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown p={0}>
        <Alert icon={<IconAlertCircle size={16} />} title="Санамж!" color="red">
          {message}
        </Alert>
      </Popover.Dropdown>
    </Popover>
  );
};

const useStyle = createStyles((theme) => ({
  addImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    border: `1px solid ${theme.colors.gray[4]}`,
  },
}));
